<template>
  <div class="workspace__container__view">
    <div class="workspace__container__view__header column">
      <div class="workspace__container__view__header__filter">
        <div class="container__inputs">
          <div class="settings-input">
            <label>{{ $t("workspace.sales.station") }}</label>
            <select name="" id="">
              <option value="" selected>{{ $t("workspace.sales.select") }} {{ $t("workspace.sales.station") }}</option>  
            </select> 
          </div>
          <div class="settings-input">
            <label>{{ $t("workspace.sales.agency") }}</label>
            <select name="" id="">
              <option value="" selected>{{ $t("workspace.sales.select") }} {{ $t("workspace.sales.agency") }}</option>  
            </select> 
          </div>
          <div class="settings-input">
            <label>{{ $t("workspace.sales.client") }}</label>
            <select name="" id="">
              <option value="" selected>{{ $t("workspace.sales.select") }} {{ $t("workspace.sales.client") }}</option>  
            </select> 
          </div>
          <div class="settings-input">
            <label>{{ $t("workspace.sales.from") }}</label>
            <input type="date" v-model="filterModel.name">
          </div>
          <div class="settings-input">
            <label>{{ $t("workspace.sales.to") }}</label>
            <input type="date" v-model="filterModel.name">
          </div>
          <div class="settings-input">
            <label>{{ $t("workspace.sales.view") }}</label>
            <select name="" id="">
              <option value="" selected>Normal</option>  
              <option value="" selected>Datos de negocio</option>  
            </select> 
          </div>
          <button>{{ $t("workspace.profile.report") }}</button>
        </div>
      </div>

      <div class="workspace__container__view__header__filter">
        <div class="container__inputs">
          <div class="settings-input">
            <label>{{ $t("workspace.sales.columnX") }}</label>
            <select name="" id="">
              <option value="" selected>{{ $t("workspace.sales.select") }} {{ $t("workspace.sales.columnX") }}</option>  
            </select> 
          </div>
          <div class="settings-input">
            <label>{{ $t("workspace.sales.columnY") }}</label>
            <select name="" id="">
              <option value="" selected>{{ $t("workspace.sales.select") }} {{ $t("workspace.sales.columnY") }}</option>  
            </select> 
          </div>
          <div class="settings-input">
            <label>{{ $t("workspace.sales.columnZ") }}</label>
            <select name="" id="">
              <option value="" selected>{{ $t("workspace.sales.select") }} {{ $t("workspace.sales.columnZ") }}</option>  
            </select> 
          </div>
          <div class="settings-input">
            <label>{{ $t("workspace.sales.operation") }}</label>
            <select name="" id="">
              <option value="" selected>{{ $t("workspace.sales.select") }} {{ $t("workspace.sales.operation") }}</option>  
            </select> 
          </div>
        </div>
      </div>
    </div>

    <div class="workspace__container__view__actions">
      <div>
        <button>{{ $t("workspace.sales.load_preset") }}</button>
        <button>{{ $t("workspace.sales.save_preset") }}</button>
      </div>
      <button>{{ $t("workspace.sales.pivot") }}</button>
      <button>{{ $t("workspace.sales.excel") }}</button>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'

export default {
  setup() {
    const filterModel = ref({
      code: '',
      name: ''
    })

    
    return {
      filterModel
    }
  }
}
</script>
<style lang="">
  
</style>